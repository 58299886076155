<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicine.vue
Description:This file gives the form where the medicine realted information of particular pond was filled by user
-->
<template>
  <layout-pondlogs containerType="card-footer" class="culture-medicine-view">
    <template slot="title">{{ $t("Comn_medicines") }}</template>

    <template slot="header">
      <medicine-action-bar @reload="initComponent"></medicine-action-bar>
    </template>

    <medicine-table
      v-loading="loading"
      element-loading-background="white"
      :key="location._id"
      slot="layout-pondlogs-scrollable-main"
      @reload="initComponent"
      :pageloading="loading"
    >
      ></medicine-table
    >

    <template slot="card-footer">
      <el-col :span="18" class="footer-container">
        <p class="note-text" v-html="$t('Medicines_abbr')"></p>
      </el-col>
    </template>
  </layout-pondlogs>
</template>

<script>
import medicineActionBar from "@/components/culture/medicineActionBar";
import medicineTable from "@/components/culture/medicineTable";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters, mapActions } from "vuex";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
export default {
  components: { medicineActionBar, medicineTable },
  mixins: [errorHandlerMixin],
  data: function() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    location: function() {
      return this.getCurrUserLocation;
    },
    locationId: function() {
      return this.location._id;
    }
  },
  async mounted() {
    await this.initComponent();
  },
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter medicine", to, from);
    handleStoreRegistration(to, from);
    next();
  },
  methods: {
    ...mapActions("resource", {
      fetchAllResources: "fetchAllResources",
      fetchAllVendors: "fetchAllVendors"
    }),
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("stock", {
      createStock: "createStock",
      updateFeedStock: "updateFeedStock",
      fetchIndividualStockSummary: "fetchIndividualStockSummary",
      fetchFeedStocks: "fetchFeedStocks"
    }),
    async initComponent() {
      try {
        this.loading = true;
        this.fetchFeedStocks();
        await this.fetchAllResources({ get_all: true });
        await this.fetchAllPonds({
          location_id: this.location._id,
          get_all: true,
          status: ["ACTIVE"]
        });
        await this.fetchAllVendors();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.culture-medicine-view {
  .er-data-tables .pagination-bar .el-pagination button.el-button--primary {
    background: #409eff;
    color: white;
    margin-left: 10rem;
    width: 80px;
  }

  .er-button.er-button--danger {
    border-radius: 12px;
    height: 27px;
    line-height: 0.5;
    width: 80px;
  }
  .er-data-tables .pagination-bar .el-pagination button.el-button--danger {
    background: #f56c6c;
    color: white;
    // margin-left: 10rem;
    width: 80px;
  }
  .note-text {
    font-weight: bold;
    opacity: 0.6;
  }
}
</style>
