<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicineActionBar.vue
Description: This file is the part of the medicine page.It has single date picker component for selecting the date which is the day of medicine used in the pond
-->
<template>
  <layout-toolbar justify="end">
    <!-- <el-col :span="3" class="day-picker-top"> -->
    <er-single-day-picker
      :value="selectedDate"
      value-format="yyyy-MM-dd"
      :format="this.upm__getFormatDateString"
      @change-prev-day="prevDayClick"
      @change-next-day="nextDayClick"
      @change-day="handlePresentDateChange"
    ></er-single-day-picker>
    <!-- </el-col> -->
  </layout-toolbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin],
  data: function() {
    return {
      selectedDate: ""
    };
  },
  created() {
    this.selectedDate = this.dhm__formatTZ(
      this.dhm__dateUtilsLib.getCurrDateInGivenTZ(this.getUserTimeZoneString),
      "yyyy-MM-dd"
    );
    this.changeDate(this.selectedDate);
  },

  beforeDestroy() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  computed: {
    ...mapGetters("stock", {
      getStateOfDialogBox: "getStateOfDialogBox"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("pondDetails", {
      getCurrentCulture: "getCurrentCulture"
    }),
    dialogVisibleStatus: {
      get() {
        return this.getStateOfDialogBox;
      },
      set(value) {
        this.changeDialogBoxVisibleStatus(value);
      }
    }
  },
  methods: {
    ...mapActions("stock", {
      changeDialogBoxVisibleStatus: "changeDialogBoxVisibleStatus"
    }),
    ...mapActions("medicines", {
      changeDate: "changeDate"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async prevDayClick(date) {
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Medicines Table - Date Filter" });
      this.changeDate(date);
    },
    async nextDayClick(date) {
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Medicines Table - Date Filter" });
      this.changeDate(date);
    },
    async handlePresentDateChange(date) {
      console.log("date changed");
      // await this.$emit("reload");
      this.selectedDate = date;
      this.mixPanelEventGenerator({ eventName: "Medicines Table - Date Filter" });
      this.changeDate(date);
    }
  }
};
</script>

<style lang="scss">
.stock-medicine-view {
  h3 {
    font-weight: 600;
  }
  .medicine-header {
    margin-bottom: 16px;
    .el-date-editor.el-input {
      width: auto;
    }
  }
  .day-picker-top {
    display: flex;
    .el-input__inner {
      border: 0px solid #dcdfe6 !important;
      padding-right: 0px;
      border-radius: 8px;
    }
    img {
      width: 11px;
      &:hover {
        cursor: pointer;
      }
    }
    .next-day-icon {
      margin-left: 2px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100px;
    }
  }
}
</style>
